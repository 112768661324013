import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = {
  key: 0,
  class: "user-app"
}
const _hoisted_3 = { id: "logo-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "view" }
const _hoisted_6 = {
  key: 1,
  class: "guest-app"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mc_notifications = _resolveComponent("mc-notifications")!
  const _component_mc_nav_bar = _resolveComponent("mc-nav-bar")!
  const _component_mc_hamburger = _resolveComponent("mc-hamburger")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mc_notifications),
    (_ctx.userIsConnected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.userIsConnected)
            ? (_openBlock(), _createBlock(_component_mc_nav_bar, {
                key: 0,
                compress: true,
                links: _ctx.itemsNavBar,
                "mobile-width": 1000,
                "active-nav-mobile": _ctx.activeNavMobile
              }, {
                logo: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: require('@/assets/img/logo_ecoPms.png')
                    }, null, 8, _hoisted_4)
                  ])
                ]),
                _: 1
              }, 8, ["links", "active-nav-mobile"]))
            : _createCommentVNode("", true),
          (_ctx.userIsConnected)
            ? (_openBlock(), _createBlock(_component_mc_hamburger, {
                key: 1,
                modelValue: _ctx.activeNavMobile,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeNavMobile) = $event)),
                class: _normalizeClass({'active': _ctx.activeNavMobile}),
                variant: _ctx.activeNavMobile ? 'white' : 'primary'
              }, null, 8, ["modelValue", "class", "variant"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_HeaderComponent),
            _createElementVNode("main", null, [
              _createVNode(_component_router_view)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("main", null, [
            _createVNode(_component_router_view)
          ])
        ]))
  ]))
}