export default {
	'dashboard'              : 'Dashboard',
	'trip'                   : 'Trip',
	'plane'                  : 'Plane',
	'boat'                   : 'Boat',
	'truck'                  : 'Truck',
	'train'                  : 'Train',
	'connect'                : 'Connect',
	'week'                   : 'Week',
	'year'                   : 'Year',
	'trip realized'          : 'Trip realized',
	'month'                  : 'Month',
	'months'                 : 'Months',
	'public dashboard'       : 'Public dashboard',
	'private dashboard'      : 'Private dashboard',
	'co2 saved'              : 'CO2 saved',
	'registered journeys'    : 'Registered journeys',
	'GESC balance'           : 'GESC balance',
	'trip history'           : 'Trip history',
	'registration date'      : 'Registration date',
	'trip reference'         : 'Trip reference',
	'blockchain reference'   : 'Blockchain reference',
	'tokens received'         : 'Tokens received',
	'blockchain transaction' : 'Blockchain transaction',
	'reference calculator'   : 'Reference calculator',
	'private'                : 'Private',
	'public'                 : 'Public',
	'login'                  : 'Login',
	'email'                  : 'Email',
	'password'               : 'Password',
	'forget your password ?' : 'Forget your password ?',
	'create an account'      : 'Create an account',
	'logout'                 : 'Logout',
	'sign in'                : 'Sign in',
	'register'               : 'Register',
	'confirm password'       : 'Confirm password',
	'sign up'                : 'Sign up',
	'no data'                : 'No data',
	'details'                : 'details'
}