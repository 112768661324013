import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mc_button = _resolveComponent("mc-button")!
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_RegisterModal = _resolveComponent("RegisterModal")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_mc_button, {
      variant: "outline-primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('logout')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_LoginModal, {
      show: _ctx.showLoginModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLoginModal = false))
    }, null, 8, ["show"]),
    _createVNode(_component_RegisterModal, {
      show: _ctx.showRegisterModal,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRegisterModal = false))
    }, null, 8, ["show"])
  ]))
}