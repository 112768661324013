
import HeaderComponent from '@/components/Header.vue'
import { defineComponent } from 'vue'
import { RouteRecord } from 'vue-router'
export default defineComponent({
	name       : 'App',
	components : { HeaderComponent },
	data() {
		return {
			isMobileScreen  : false as boolean,
			activeNavMobile : false as boolean
		}
	},
	computed : {
		userIsConnected() {
			if(this.$user) return this.$user.token
			return false
		},
		itemsNavBar(): [] {
			return this.$clone(this.$router.options.routes).filter((route: RouteRecord) => {
				if(route.meta) {
					//if(this.userIsConnected) return route.meta.nav
					return route.meta.nav //&& route.meta.guest
				}
			})
		}
	},
	watch : {
		$route() {
			if(this.activeNavMobile) {
				this.activeNavMobile = !this.activeNavMobile
			}
		}
	}
})
