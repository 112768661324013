import { RouteRecordRaw } from 'vue-router'
import { store } from '@/store/index'
const routes: Readonly<RouteRecordRaw>[] = [
	{
		path     : '/',
		redirect : () => {
			const user = store.getters['user/getUser']
			if(user) return '/dashboard/public/'
			else return '/login'
		}
	},
	{
		path     : '/:pathMatch(.*)*',
		redirect : '/'
	},
	{
		path       : '/dashboard/public',
		name      : 'dashboard-public',
		component : () => import('@/views/Dashboard.vue'),
		meta      : {
			title       : 'public dashboard',
			nav         : {
				title : 'public dashboard',
				icon  : 'dashboard'
			}
		}
	},
	{
		path       : '/dashboard/private',
		name      : 'dashboard-private',
		component : () => import('@/views/Dashboard.vue'),
		meta      : {
			title       : 'private dashboard',
			nav         : {
				title : 'private dashboard',
				icon  : 'dashboard'
			}
		}
	},
	{
		path      : '/login',
		name      : 'login',
		component : () => import('@/views/Login.vue'),
		meta      : {
			title : 'login',
			guest : true
		}
	},
	{
		path      : '/register',
		name      : 'register',
		component : () => import('@/views/Register.vue'),
		meta      : {
			title : 'register',
			guest : true
		}
	}
]
export default routes