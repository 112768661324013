
import { defineComponent } from 'vue'
export default defineComponent({
	props : {
		show : {
			type     : Boolean,
			required : false
		}
	},
	name : 'LoginModal',
	data() {
		return {
			loading : false as boolean,
			user    : {
				username : null,
				password : null
			}
		}
	},
	methods : {
		async login() {
			this.loading = true
			await this.$http.post('/auth/signin', this.user).then((res: any) => {
				this.$store.commit('user/set', res.data)
			}).catch((err: any) => {
				this.$notify({
					variant : 'alert',
					title   : this.$t('error'),
					text    : err
				})
			})
			this.loading = false
		}
	}
})
