
import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import {
	vEmail, vPassword, vSameAsPassword, vRequired
} from '@/utils/vuelidate'
export default defineComponent({
	name  : 'RegisterModal',
	props : {
		show : {
			type     : Boolean,
			required : true
		}
	},
	data() {
		return {
			v$   : useVuelidate(),
			user : {
				username : 'rbensimon05@gmail.com' as string,
				password : 'raphael' as string,
				role     : 'test' as string
			},
			confirmPassword : 'omg' as string,
			loading         : false
		}
	},
	validations() {
		const t = this
		return {
			user : {
				username : {
					vEmail,
					vRequired
				},
				password : {
					password : vPassword,
					vRequired
				}
			},
			confirmPassword : {
				sameAs : vSameAsPassword(t.user.password),
				vRequired
			}
		}
	},
	methods : {
		async register() {
			this.loading = true
			this.v$.$touch()
			await this.$http.post('/user', this.user).then((res: any) => {
				this.$router.push('/login')
			}).catch((err: any) => {
				this.$notify({
					variant : 'alert',
					title   : this.$t('error'),
					text    : err
				})
			})
			this.loading = false
		}
	}
})
