
import {
	LoginModal, RegisterModal
} from './header/'
import { defineComponent } from 'vue'
export default defineComponent({
	components : {
		LoginModal,
		RegisterModal
	},
	data() {
		return {
			showLoginModal    : false as boolean,
			showRegisterModal : false as boolean
		}
	}
})
