
import { defineComponent } from 'vue'
export default defineComponent({
	methods : {
		$clone(val: any) {
			return JSON.parse(JSON.stringify(val))
		}
	},
	computed : {
		$user() {
			return this.$store.getters['user/get']
		}
	}
})