import { createApp } from 'vue'
import App from './App.vue'
import i18n from './translations/i18n'
import components from '@maunaconsulting/vue-components'
import { notify } from '@maunaconsulting/vue-components'
import routes from './router/index'
import common from '@/mixins/common'
import { store } from '@/store/index'
import { http } from '@/utils/https'
const app = createApp(App)
app.config.globalProperties.$http = http
app.config.globalProperties.$notify = notify
app.mixin(common)
app.use(components)
app.use(store)
app.use(i18n)
app.use(routes)

app.mount('#app')
